import React, { useEffect, useState } from "react";

import clsx from "clsx";
import { navigate } from "gatsby";

import { listTelehealthPatients } from "~/src/api-client/patients";
import DashboardLayout from "~/src/components/DashboardLayout";
import { useContextUpdate, useContextValue } from "~/src/context";
import { TelehealthPatients } from "~/src/types/patients";

const PatientList = () => {
  const [patients, setPatients] = useState<TelehealthPatients[]>([]);

  const { session, rehydrated, medic, preLoading } = useContextValue();
  const { setPreLoadingStatus } = useContextUpdate();

  const fetchPatients = async (token: string) => {
    setPreLoadingStatus(true);
    const patientsList = await listTelehealthPatients(token);
    if (patientsList) {
      setPatients(patientsList.data);
    }
    setPreLoadingStatus(false);
  };

  useEffect(() => {
    if (rehydrated) {
      if (!session) {
        navigate("/");
      } else if (medic && !medic.active) {
        navigate("/app");
      } else {
        fetchPatients(session?.accessToken);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rehydrated, medic, session]);

  type PatientInfoProps = {
    patientInfo: TelehealthPatients;
  };

  function PatientInfo({ patientInfo }: PatientInfoProps) {
    const [date, time] = patientInfo.telehealth_date.split(" ");

    return (
      <div className={clsx("flex justify-between", "bg-white", "py-4", "items-center")}>
        <div>
          <div className="flex gap-x-3">
            <span className="font-medium">{patientInfo.patient.fullname}</span>{" "}
            <span className="text-examedi-gray-regular">{patientInfo.patient.document_number}</span>
          </div>{" "}
          <p>
            {date} - <span className="text-examedi-gray-regular">{time} hrs</span>
          </p>{" "}
        </div>
        <div>
          <button
            onClick={() => {
              navigate(`/app/prescriptions`, {
                state: {
                  telehealthId: patientInfo.id,
                  patientId: patientInfo.patient.id,
                },
              });
            }}
            className={clsx(
              "ml-2",
              "px-4",
              "py-2",
              "rounded-full",
              "font-medium",
              "text-white",
              "border border-examedi-blue-strong hover:border-examedi-blue-soft",
              "bg-examedi-blue-strong hover:bg-examedi-blue-soft",
              "drop-shadow-examedi-blue-strong",
            )}
          >
            Emitir orden médica
          </button>
          <button
            onClick={() => {
              navigate(`/app/patients/${patientInfo.patient.id}/derivate`);
            }}
            className={clsx(
              "ml-2",
              "px-10",
              "py-2",
              "rounded-full",
              "font-medium",
              "text-examedi-blue-strong hover:text-white",
              "bg-white hover:bg-examedi-blue-strong",
              "border border-examedi-blue-strong",
            )}
          >
            Derivar a especialista
          </button>
          <button
            onClick={() => {
              navigate(`/app/patients/${patientInfo.patient.id}/results`);
            }}
            className={clsx(
              "ml-2",
              "px-10",
              "py-2",
              "rounded-full",
              "font-medium",
              "text-examedi-blue-strong hover:text-white",
              "bg-white hover:bg-examedi-blue-strong",
              "border border-examedi-blue-strong",
            )}
          >
            Ver Historial
          </button>
        </div>
      </div>
    );
  }

  function Header() {
    return (
      <div className={clsx("bg-white", "p-5 md:p-10", "w-full", "flex flex-col", "gap-y-1")}>
        <div className="text-lg md:text-[1.75rem] font-bold">Gestiona tus citas de telemedicina</div>
        <div className="text-xs md:text-xl text-gray-400">
          Ingresa la información de tu paciente y nosotros nos encargamos del resto
        </div>
      </div>
    );
  }

  function AppointmentPatients() {
    return (
      <>
        <div
          className={clsx(
            "grid divide-y rounded-2xl border border-examedi-gray-line bg-white divide-examedi-gray-line",
            "px-6",
          )}
        >
          {patients?.map((patientInfo, index) => (
            <PatientInfo key={index} patientInfo={patientInfo} />
          ))}
        </div>
        {!preLoading && patients.length === 0 && <p>No tienes citas de telemedicina para el día de hoy</p>}
      </>
    );
  }

  return (
    <DashboardLayout header={<Header />} childrenClassName="bg-white px-10">
      <AppointmentPatients />
    </DashboardLayout>
  );
};

export default PatientList;
